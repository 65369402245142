import React from "react"
import styled from "styled-components"
import Image from "gatsby-image/withIEPolyfill"

import RichText from "src/components/RichText"
import { formatMoney } from "src/helpers/text"
import { useCart } from "src/helpers/cart"
import { COLORS, SIZES, FONTS } from "src/helpers/constants"
import Add from "src/svg/add.svg"
import Remove from "src/svg/remove.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const Photo = styled(Image)`
  width: 100%;
`
const Content = styled.div`
  background-color: ${COLORS.white};
  padding: 10px;
  width: 100%;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  h2,
  h3,
  h4 {
    margin: 0;
    font-family: ${FONTS.text};
  }
  h2,
  h3 {
    font-weight: bold;
  }
`
const Title = styled.h2`
  font-size: ${SIZES.menu}px;
`
const Discount = styled.h3`
  font-size: ${SIZES.medium}px;
  font-style: italic;
`
const Type = styled.h4`
  font-size: ${SIZES.medium}px;
  font-weight: 500;
`
const Description = styled(RichText)`
  flex: 1;
  p {
    margin-top: 0.5em;
  }
`
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`
const Price = styled.div`
  padding: 10px;
  background-color: ${COLORS.yellow};
  font-family: ${FONTS.title};
  font-size: ${SIZES.menu}px;
`
const Actions = styled.div`
  font-size: ${SIZES.label}px;
  font-family: ${FONTS.title};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90px;
`
const Action = styled.button`
  border: 1px solid ${COLORS.yellow};
  border-radius: 15px;
  width: 30px;
  height: 30px;
  margin-bottom: 2px;
  background-color: ${COLORS.white};
  padding: 0;
  svg {
    width: 15px;
    height: 15px;
  }
`

interface Props {
  product: Product
}

const Product: React.FC<Props> = ({ product }) => {
  const { id, title, discount, type, description, price, photo } = product
  const { cart, updateCart, active } = useCart()

  const handleAdd = (id: string, delta: number) => () => {
    updateCart(id, delta)
  }

  return (
    <Container>
      <Photo fluid={photo.fluid} alt={photo.title} />
      <Content>
        <Title>{title}</Title>
        {discount && <Discount>{discount}</Discount>}
        <Type>&gt; {type}</Type>
        <Description node={description} />
        {active && (
          <Bottom>
            <Price>{formatMoney(price)}</Price>
            <Actions>
              <Action type="button" onClick={handleAdd(id, -1)}>
                <Remove />
              </Action>
              {cart[id] || 0}
              <Action type="button" onClick={handleAdd(id, 1)}>
                <Add />
              </Action>
            </Actions>
          </Bottom>
        )}
      </Content>
    </Container>
  )
}
export default Product
