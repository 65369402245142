import React from "react"
import styled from "styled-components"

import { COLORS, SIZES, FIXED_SALES } from "src/helpers/constants"
import { formatInterval, isPast } from "src/helpers/date"
import CheckedIcon from "src/svg/radio-checked.svg"
import UncheckedIcon from "src/svg/radio-unchecked.svg"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px 40px;
`
const Option = styled.div`
  padding: 5px 10px;
  flex: 1 0 100%;
  @media (min-width: 1000px) {
    flex: 0 0 50%;
  }
`
const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  width: 100%;
  background-color: ${COLORS.white};
  padding: 7px;
  font-size: ${SIZES.medium}px;
  svg {
    margin-right: 5px;
    flex: 0 0 auto;
  }
`
const Place = styled.div`
  padding-bottom: 1px;
`
const Title = styled.span`
  font-weight: 500;
  text-transform: uppercase;
`
const FixedTitle = styled.span`
  font-weight: 500;
  font-style: italic;
`
const Address = styled.a`
  display: inline-block;
  margin-left: 34px;
  padding: 2px;
  font-style: italic;
`

interface Props {
  sales: Sale[]
  value: string
  onChange: (value: string, active: boolean) => void
}
const SalesField: React.FC<Props> = ({ sales, value, onChange }) => (
  <Container>
    {sales.map(({ id, place, date, end, address, link, closeDate }) => (
      <Option key={id}>
        <Button
          type="button"
          onClick={() => {
            onChange(id, !isPast(closeDate))
          }}
        >
          {value === id ? <CheckedIcon /> : <UncheckedIcon />}
          <Place>
            <Title>{place}</Title> • {formatInterval(date, end)}
          </Place>
        </Button>
        <Address href={link} target="_blank" rel="noopener">
          {address}
        </Address>
      </Option>
    ))}
    {FIXED_SALES.map(({ id, label }) => (
      <Option key={id}>
        <Button
          type="button"
          onClick={() => {
            onChange(id, false)
          }}
        >
          {value === id ? <CheckedIcon /> : <UncheckedIcon />}
          <Place>
            <FixedTitle>{label}</FixedTitle>
          </Place>
        </Button>
      </Option>
    ))}
  </Container>
)

export default SalesField
