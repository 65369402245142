import React from "react"
import { graphql, navigate } from "gatsby"
import styled from "styled-components"
import { Product as StructuredProduct, ItemAvailability, WithContext } from "schema-dts"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"

import Layout from "src/layout"
import Menu from "src/components/Menu"
import Product from "src/components/Product"
import Column from "src/layout/column"
import SalesField from "src/components/SalesField"
import Button from "src/components/Button"
import { formatMoney } from "src/helpers/text"
import { useCart } from "src/helpers/cart"
import { FIXED_SALES, EMAIL_ADDRESS, PHONE, TEL, SIZES, FONTS, COLORS } from "src/helpers/constants"

const Disclaimer = styled.div`
  font-style: italic;
`
const Top = styled.div`
  margin-top: 24px;
`
const Contact = styled.span`
  font-size: ${SIZES.medium}px;
  font-family: ${FONTS.text};
  vertical-align: middle;
  white-space: nowrap;
`
const Products = styled.div<{ $odd?: boolean }>`
  margin: 10px -10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > div {
    flex: 0 0 ${({ $odd }) => ($odd ? 33.33 : 25)}%;
    padding: 10px;
    @media (max-width: ${({ $odd }) => ($odd ? 850 : 1000)}px) {
      flex-basis: 50%;
    }
    @media (max-width: 600px) {
      flex-basis: 100%;
    }
  }
`
const Total = styled.h2`
  background-color: ${COLORS.white};
  padding: 6px 8px 2px;
  margin: 0 0 40px;
  text-align: right;
`

const getItemTitle = (title: string, discount: string, type: string) => {
  let output = title
  if (discount) {
    output += " : " + discount
  }
  output += " > " + type
  return output
}

const date = new Date()
date.setFullYear(date.getFullYear() + 1)
const inOneYear = date.toISOString().substr(0, 10) // YYYY-MM-DD

interface DataType {
  products: { nodes: Product[] }
  sales: { nodes: Sale[] }
  site: Site
}

const ProductsPage: GatsbyPage<DataType> = ({ data }) => {
  const products = data.products.nodes
  products.sort((left, right) => (left.position > right.position ? 1 : -1))

  const sales = data.sales.nodes
  sales.sort((left, right) => (left.date > right.date ? 1 : -1))

  const { saleId, setSale, cart, active, setActive } = useCart()

  const items: OrderItem[] = products
    .filter(({ id }) => cart[id])
    .map(({ id, title, discount, type, price }) => ({
      id,
      name: title,
      title: getItemTitle(title, discount, type),
      price,
      quantity: cart[id],
    }))

  const handleSale = (value: string, active: boolean) => {
    setSale(value)
    setActive(active)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!saleId) {
      alert("Vous devez choisir un lieu et date de retrait")
      return
    }

    if (!items.length) {
      alert("Vous devez choisir des produits")
      return
    }

    navigate("/commande/", {
      state: {
        items,
        sale: sales.find(({ id }) => id === saleId),
      },
    })
  }

  const fixedSale = FIXED_SALES.find((sale) => sale.id === saleId)
  const total = items.reduce((value, current) => value + current.quantity * current.price, 0)

  const { title: siteTitle, siteUrl } = data.site.siteMetadata
  const firstProduct = products[0] as Product
  const structured: WithContext<StructuredProduct> = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: firstProduct.type,
    description: documentToPlainTextString(firstProduct.description.json),
    brand: {
      "@type": "Brand",
      name: siteTitle,
    },
    image: "https:" + firstProduct.photo.fluid.src,
    offers: products.map(({ title, price, id, description, photo }) => ({
      "@type": "Offer",
      priceCurrency: "EUR",
      price,
      availability: ItemAvailability.PreSale,
      priceValidUntil: inOneYear,
      sku: id,
      name: title,
      description: documentToPlainTextString(description.json),
      image: "https:" + photo.fluid.src,
      url: `${siteUrl}/produits/`,
    })),
  }

  return (
    <Layout title="Commande">
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structured) }} />
      <Menu />
      <Column $wide as="form" onSubmit={handleSubmit}>
        <Top>
          Nous récoltons le miel au cours de l’été, vous pouvez passer commande ici dès maintenant pour un retrait à
          l’automne.
        </Top>
        <h2>Je choisis mon lieu et la date de retrait :</h2>
        <SalesField sales={sales} value={saleId} onChange={handleSale} />
        {active ? (
          <h2>Je choisis mes produits :</h2>
        ) : (
          <>
            <h2>
              Contactez-nous directement{" :    "}
              <Contact>
                <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a> &nbsp; — &nbsp; <a href={TEL}>{PHONE}</a>
              </Contact>
            </h2>
            <Disclaimer>{fixedSale ? fixedSale.disclaimer : "Vente close"}</Disclaimer>
          </>
        )}
        <Products $odd={products.length % 2 !== 0}>
          {products.map((product) => (
            <Product key={product.id} product={product} />
          ))}
        </Products>
        {active && (
          <>
            <Total>Total : {formatMoney(total)}</Total>
            <Button type="submit">Je valide ma commande</Button>
          </>
        )}
      </Column>
    </Layout>
  )
}

export default ProductsPage

export const query = graphql`
  query {
    products: allContentfulProduct {
      nodes {
        id: contentful_id
        position
        title
        discount
        type
        description {
          json
        }
        photo {
          fluid(maxWidth: 275, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
        price
      }
    }
    sales: allContentfulSale {
      nodes {
        id: contentful_id
        date
        end
        place
        address
        link
        closeDate
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
